import React, {useState} from 'react';
import { Flex, Space, Card, Button, Tooltip, Drawer, Input, message } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import Icon from '@/components/Icon';

const { TextArea } = Input;

export default function GamerList(props) {
  const { keyStatus, gamers, checkedIds } = props;
  const [visible, setVisible] = useState(false);
  const [ids, setIds] = useState('');

  const onClose = () => {
    setIds('');
    setVisible(false);
  };
  const onChange = (e) => {
    setIds(e.target.value);
  };
  const onSubmit = () => {
    if (!keyStatus) {
      message.error('目前无公共 key，请点击右上方红色按钮手动添加 key');
      return;
    }
    // 最多添加10个玩家
    if (ids.split('\n').length > 10) {
      message.error('最多添加10个玩家');
      return;
    }
    setVisible(false);
    setIds('');
    const reg = /\n+/g;
    props.onSubmit([...new Set(ids.split(reg))].filter((id) => id));
  };
  const onShowBack = () => {
    setIds(gamers.map((gamer) => gamer.user.steamid).join('\n'));
  };
  const onGamerClick = (id) => {
    props.onGamerCheckedChange(id);
  };
  return (
    <>
      <Card
        className='shadow-lg rounded-lg'
        bordered
        style={{ width: '100%', height: '100%' }}
        styles={{ body: { height: 'calc(100% - 70px)', overflowY: 'scroll', padding: 12, minHeight: 60 } }}
        title='玩家列表'
        extra={<Button onClick={() => setVisible(true)} type='text' icon={<PlusCircleOutlined />} />}
      >
        {
          gamers.length === 0 ? <div>
            <p className='text-center text-gray-400 pt-[10px]'>请点击右上方按钮添加玩家</p>
          </div> : <div className="flex flex-col gap-y-2 cursor-pointer">
            {
              gamers.map((gamer) => <div onClick={() => onGamerClick(gamer.user.steamid)}
                                         className="relative flex items-center box-content p-2 bg-slate-100 rounded"
                                         key={gamer.user.steamid}>
                {
                  checkedIds.includes(gamer.user.steamid) &&
                  <Icon className="absolute right-[4px] top-[-2px]" type="icon-checked"/>
                }
                <img className="rounded-full mr-2" src={gamer.user.avatar} alt="user-avatar"/>
                <div className="flex-auto flex justify-between items-center">
                  <Tooltip title={gamer.user.personaname}>
                    <p className="truncate max-w-[7vw] max-lg:max-w-[55vw]">{gamer.user.personaname}</p>
                  </Tooltip>
                  <span className="text-xs">库存: {gamer.stock?.length}</span>
                </div>
              </div>)
            }
          </div>
        }
      </Card>
      <Drawer
        title="添加玩家"
        width={390}
        open={visible}
        onClose={onClose}
        placement="left"
        maskClosable={false}
      >
        <div className="relative">
          <TextArea
            value={ids}
            onChange={onChange}
            style={{minHeight: 200}}
          />
          {!ids && (
            <div className="absolute top-[-2px] left-[6px] p-2 text-gray-400 pointer-events-none z-0">
              <p>请输入 steam id，多个 id 请换行输入 (enter / shift+enter)</p><br/>
              <p>如果已经添加了玩家，还想要继续添加，请先点击回显按钮，然后再填入新的 steam id</p><br/>
              <p>不清楚如何获取 steam id 请点击右侧第一个悬浮按钮查看详情</p>
            </div>
          )}
        </div>
        <Flex justify="space-between" className="mt-2">
          <Tooltip title="将已经添加的玩家id显示在输入框中">
            <Button disabled={gamers.length <= 0} type="primary" onClick={onShowBack}>
              回显
            </Button>
          </Tooltip>
          <Space>
            <Button onClick={onClose}>关闭</Button>
            <Button type="primary" onClick={onSubmit}>
              确定
            </Button>
          </Space>
        </Flex>
      </Drawer>
    </>
  );
}

import React from 'react';
import { createFromIconfontCN } from '@ant-design/icons';

const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_4539793_rxqhc5863ue.js',
});

export default function index(props) {
  const { type, size = 12, ...otherProps } = props;
  return (
    <div {...otherProps}>
      <IconFont style={{ fontSize: size }} type={type}></IconFont>
    </div>
  );
}

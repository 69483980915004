import React from 'react';
import ReactDOM from 'react-dom/client';
import { ConfigProvider } from 'antd';
import zhcn from 'antd/lib/locale/zh_CN';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <ConfigProvider locale={zhcn} theme={{
    token: {
      colorPrimary: '#f38181',
    }
  }}>
    <App />
  </ConfigProvider>
  // </React.StrictMode>
);

import React, {useState, useEffect} from "react";
import {Spin, Modal, Tag, message} from "antd";
import AD from "./components/AD";
import Header from "./components/Header";
import GamerList from './components/GamerList';
import GameTable from "./components/GameTable";
// import fetchData from './fetchData.json';
import { buryEvent, clickEvent } from "./utils/tracking";
import { onGameNameClick, getRandomColor } from "./utils";
import { getGamersInfo } from "./service";
import { getStorage } from "./utils";
import './App.css';

const getAddSuccessInfo = (gamers) => {
	return gamers.map(gamer => {
		return {
			isPublic: gamer.isPublic,
			name: gamer.user.personaname,
			success: gamer.stock.length,
			fail: gamer.failGameId,
		};
	});
};

function App() {
	const [loading, setLoading] = useState(false);
	const [gamersIds, setGamersIds] = useState([]);
	const [gamerCheckedIds, setGamerCheckedIds] = useState([]);
	const [gamers, setGamers] = useState([]);
	const [keyStatus, setKeyStatus] = useState(false);
	const [addSuccessVisible, setAddSuccessVisible] = useState(false);
	const [addSuccessInfo, setAddSuccessInfo] = useState([]);

	const onGamersSubmit = (g) => {
		setGamersIds(g);
	};
	const onGamerCheckedChange = (id) => {
		// 最多选择6个玩家 最少选择1个玩家
		if (gamerCheckedIds.includes(id)) {
			if (gamerCheckedIds.length === 1) {
				message.error('至少选择一个玩家');
				return;
			}
			setGamerCheckedIds(gamerCheckedIds.filter(g => g !== id));
		} else {
			if (gamerCheckedIds.length === 6) {
				message.error('最多选择6个玩家');
				return;
			}
			setGamerCheckedIds([...gamerCheckedIds, id]);
		}
	};

	const getGamersData = (ids) => {
		if (ids.length === 0) {
			// setGamerCheckedIds(fetchData.data.map(g => g.user.steamid));
			// setGamers(fetchData.data);
			return;
		}
		const newIds = ids.filter(i => !gamers.map(j => j?.user?.steamid).includes(i));
		if (newIds.length === 0) {
			return;
		}
		setLoading(true);
		getGamersInfo(newIds, getStorage('steam_key')).then(res => {
			if (res.code !== 200) {
				message.error(res.msg);
				return;
			}
			setAddSuccessInfo(getAddSuccessInfo(res.data));
			setAddSuccessVisible(true);
			clickEvent('Search');
			const _gamers = [...gamers, ...res.data];
			setGamers(_gamers);
			setGamerCheckedIds(gamerCheckedIds.length > 0 ? gamerCheckedIds : _gamers.map(g => g.user.steamid).slice(0, 6));
		}).catch(err => {
			message.error('获取玩家信息失败');
		}).finally(() => {
			setLoading(false);
		});
	};

	useEffect(() => {
		getGamersData(gamersIds);
		// getGamersInfo(gamersIds);
	}, [gamersIds]);

	const [showAd, setShowAd] = useState(getStorage('isShowAd', { type: 'sessionStorage' }) ?? true);

	useEffect(() => {
		const url = new URL(window.location.href);
		const gamersIds = url.searchParams.get('gamersIds');
		if(gamersIds) {
			setGamersIds(gamersIds.split(','));
		}
		const checkedIds = url.searchParams.get('checkedIds');
		if(checkedIds) {
			setGamerCheckedIds(checkedIds.split(','));
		}
		buryEvent();
	}, []);
	return (
		<div className="App bg-[#eef3fe]">
				<Spin spinning={loading} fullscreen />
				{
					showAd && <AD onClose={() => setShowAd(false)} />
				}
				<Header keyStatus={keyStatus} onChangeKeyStatus={setKeyStatus}></Header>
				<main className='flex h-main max-lg:flex-col box-border py-8 max-lg:py-2 mx-20 max-lg:mx-2'>
					<aside className='w-1/4 max-lg:w-full min-w-[250px] h-full max-lg:h-fit'>
						<GamerList keyStatus={keyStatus} gamers={gamers} checkedIds={gamerCheckedIds} onSubmit={onGamersSubmit} onGamerCheckedChange={onGamerCheckedChange} />
					</aside>
					<main className='flex-1 w-1 max-lg:w-full ml-6 max-lg:ml-0 max-lg:mt-4 h-full'>
						<GameTable gamers={gamers} checkedIds={gamerCheckedIds} />
					</main>
				</main>
			<Modal
					title='添加成功'
					open={addSuccessVisible}
					onCancel={() => setAddSuccessVisible(false)}
					footer={null}>
					<div>
						{
							addSuccessInfo.map((info) => {
								return info.success === 0 ? <div key={info.name} className="border-b border-gray-200 py-2">
										{info.isPublic ? <p>玩家 <span className="font-bold text-xl">{info.name}</span> 信息获取失败</p> :
											<p>玩家 <span className="font-bold text-xl">{info.name}</span> 信息未公开</p>}
									</div> :
									<div key={info.name} className='border-b border-gray-200 py-2'>
										<p>玩家 <span className="font-bold text-xl">{info.name}</span> {info.success} 成功 {info.fail.length} 失败</p>
										{
											info.fail.length > 0 && <>
												<span className="mr-2">添加失败游戏列表:</span>
												{info.fail.map((fail) => <Tag key={fail} className="cursor-pointer" color={getRandomColor()} onClick={() => onGameNameClick(fail)}>{fail}</Tag>)}
											</>
										}
									</div>;
							})
						}
					</div>
			</Modal>
		</div>
	);
}

export default App;

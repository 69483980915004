// import fetchData from './fetchData.json';

const formatUrl = (url, params) => {
	if (!params) {
		return url;
	}
	if (Object.keys(params).length === 0) {
		return url;
	}
	return url + '?' + Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
};

const service = async (url, options) => {
	const { params, data, method } = options;
	const res = await fetch(formatUrl(`${url}`,  params), {
		method,
		body: method === 'post' ? JSON.stringify(data) : undefined,
		headers: new Headers({
			'Content-Type': 'application/json'
		})
	});
	return res.json();
};

export const getGamersInfo = async (ids = [], key) => {
	return await service(`${process.env.REACT_APP_BASE_API}/get/gamers`, {
		method: 'post',
		data: {
			ids,
			key,
		},
	});
};


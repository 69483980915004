import React, {useState, useMemo, useRef, useEffect} from 'react';
import {Card, Flex, Button, Table, Space, Input, message, Modal, Tag} from 'antd';
import {SearchOutlined, FilterOutlined, EyeOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import FloatButtonGroup from "../FloatButtonGroup";
import { coypUrlWithParams, formatStock, onGameNameClick, dealToolTipText, getRandomColor } from '@/utils';
import { clickEvent } from "../../utils/tracking";

const GameTable = (props) => {
	const {gamers, checkedIds} = props;

	const stocks = useMemo(() => {
		return formatStock(gamers.filter(g => checkedIds?.includes(g.user.steamid)));
	}, [gamers, checkedIds]);
	const [searchText, setSearchText] = useState('');
	const [searchedColumn, setSearchedColumn] = useState('');
	const searchInput = useRef(null);
	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};
	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText('');
	};
	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
			<div
				className="p-[8px] min-w-[300px] max-lg:min-w-[200px]"
				onKeyDown={(e) => e.stopPropagation()}
			>
				<Input
					ref={searchInput}
					placeholder={`搜索游戏`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{
						marginBottom: 8,
						display: 'block',
					}}
				/>
				<Space className="flex justify-end">
					<Button
						onClick={() => clearFilters && handleReset(clearFilters)}
						size="small"
						style={{
							width: 90,
						}}
					>
						重置
					</Button>
					<Button
						type="primary"
						onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined/>}
						size="small"
						style={{
							width: 90,
						}}
					>
						搜索
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => (
			<SearchOutlined
				style={{
					color: filtered ? '#1677ff' : undefined,
				}}
			/>
		),
		onFilter: (value, record) =>
			record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
		onFilterDropdownOpenChange: (visible) => {
			if (visible) {
				setTimeout(() => searchInput.current?.select(), 100);
			}
		},
		render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{
						backgroundColor: '#ffc069',
						padding: 0,
					}}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ''}
				/>
			) : (
				text
			),
	});
	const [visible, setVisible] = useState(false);
	const [curWhatCanIGetInfo, setCurWhatCanIGetInfo] = useState({
		user: {},
		notHasedStocks: [],
		familySharingStocks: [],
		notFamilySharingStocks: [],
	});
	const onShowWhatCanIGet = (steamid) => {
		if (checkedIds.length <=1 ) {
			message.warning('请至少选择两个玩家');
			return;
		}
		clickEvent('WhatCanIGet');
		const notHasedStocks = stocks.filter(s => !s.subordinate.includes(steamid));
		setCurWhatCanIGetInfo({
			user: gamers.find(g => g.user.steamid === steamid).user,
			notHasedStocks: stocks.filter(s => !s.subordinate.includes(steamid)), // 未拥有的游戏
			familySharingStocks: notHasedStocks.filter(s => s.categories?.find(c => c.id === 62)), // 未拥有的游戏中支持家庭共享的游戏
			notFamilySharingStocks: notHasedStocks.filter(s => !s.categories?.find(c => c.id === 62)), // 未拥有的游戏中不支持家庭共享的游戏
		});
		setVisible(true);
	};
	const getColumns = (gamers) => {
		const result = [
			{
				title: 'Game',
				dataIndex: 'name',
				key: 'name',
				fixed: 'left',
				...getColumnSearchProps('name'),
				width: 150,
				render: (text, record) => {
					return <Button
						className="max-w-full text-left"
						style={{ padding: 0 }}
						type="link"
						onClick={() => onClickGameName(record)}
					><p className="w-full text-left truncate">{text}</p></Button>;
				},
			},
			{
				title: '价格',
				dataIndex: 'price_overview',
				key: 'price_overview',
				width: 100,
				defaultSortOrder: 'descend',
				sorter: (a, b) => {
					// 存在三种情况 分别是 is_free 为 true 这时页面展示免费， 剩下两种情况是 is_free 都是 false, 但是 可能存在price_overview 不存在的情况这时页面展示暂无价格， 最后一种情况是 price_overview 存在，final 也存在这时页面展示价格
					// 现在需要对这三种情况进行排序， 免费，暂无价格，价格
					if (a.is_free && b.is_free) {
						return 0;
					}
					if (a.is_free) {
						return -1;
					}
					if (b.is_free) {
						return 1;
					}
					if (!a.price_overview && !b.price_overview) {
						return 0;
					}
					if (!a.price_overview) {
						return -1;
					}
					if (!b.price_overview) {
						return 1;
					}
					if (a.price_overview.final === b.price_overview.final) {
						return 0;
					}
					return a.price_overview.final > b.price_overview.final ? 1 : -1;
				},
				render: (text, record) => {
					if (record.is_free) {
						return '免费';
					}
					if (!text) {
						return '暂无价格';
					}
					if (text.discount_percent) {
						return <div>
							<p className="line-through text-gray-400">{text.initial_formatted}</p>
							<p>{text.final_formatted}</p>
						</div>;
					}
					return text.final_formatted;
				},
			}
		];
		gamers.forEach((gamer) => {
			const { user } = gamer;
			result.push({
				title: () => <div className="flex justify-between">
					<span>{dealToolTipText(user.personaname)}</span>
					<EyeOutlined onClick={() => { onShowWhatCanIGet(user.steamid); }} />
				</div>,
				dataIndex: user.subordinate,
				key: user.subordinate,
				width: 100,
				render: (text) => {
					if (typeof text.subordinate === 'string') {
						return text.subordinate === user.steamid ? <CheckCircleTwoTone className="text-xl" twoToneColor="#52c41a" /> : <CloseCircleTwoTone className="text-xl" twoToneColor="#eb2f96" />;
					}
					return text.subordinate?.includes(user.steamid) ? <CheckCircleTwoTone className="text-xl" twoToneColor="#52c41a" /> : <CloseCircleTwoTone className="text-xl" twoToneColor="#eb2f96" />;
				}
			});
		});
		return result;
	};
	const columns = useMemo(() => {
		return getColumns(gamers.filter(g => checkedIds?.includes(g.user.steamid)));
	}, [gamers, checkedIds]);

	const tableRef = useRef(null);
	const [contentHeight, setContentHeight] = useState(0);
	const onTableScrollTop = () => {
		tableRef.current?.scrollTo({index: 0, smooth: true, behavior: 'smooth', top: 0});
	};
	const onShared = () => {
		if (gamers.length === 0) {
			message.error('请先添加玩家');
			return;
		}
		clickEvent('Shared');
		coypUrlWithParams({
			params: {gamersIds: gamers.map(g => g.user.steamid).join(','), checkedIds: checkedIds.join(',')},
		});
	};

	const [curGameInfo, setCurGameInfo] = useState({});
	const [gameInfoVisible, setGameInfoVisible] = useState(false);
	const onClickGameName = (record) => {
		setCurGameInfo(record);
		setGameInfoVisible(true);
	};

	useEffect(() => {
		// 观察 document.getElementById('GameTable') 如果高度发生变化，重新计算 contentHeight
		const observer = new ResizeObserver((entries) => {
			for (let entry of entries) {
				const { height } = entry.contentRect;
				setContentHeight(height - 164);
			}
		});
		observer.observe(document.getElementById('GameTable'));
		return () => {
			observer.disconnect();
		};
	}, []);
	return <Card id="GameTable" className='shadow-lg rounded-lg overflow-y-auto' bordered style={{ width: '100%', height: '100%' }} >
		<Flex justify="flex-end" align="center" className="mb-2">
			{/*<SearchFilteror />*/}
		</Flex>
		<Table
			ref={tableRef}
			sticky
			rowKey="steam_appid"
			columns={columns}
			dataSource={stocks}
			pagination={false}
			scroll={{ x: 300 + (gamers.length ?? 0) * 100, y: isNaN(contentHeight) ? 0 : contentHeight, scrollToFirstRowOnChange: true }}
			summary={() => {
				if (stocks.length === 0) {
					return '';
				}
				return <Table.Summary fixed>
					<Table.Summary.Row>
						<Table.Summary.Cell index={0} colSpan={2}>库存总计：{stocks.length}</Table.Summary.Cell>
						{
							checkedIds.map((id, index) => {
								const stock = stocks.filter(s => s.subordinate.includes(id));
								return <Table.Summary.Cell key={index} index={index + 2}>库存数量: {stock.length}</Table.Summary.Cell>;
							})
						}
					</Table.Summary.Row>
				</Table.Summary>;
			}}
		/>
		<FloatButtonGroup onShared={onShared} onTableScrollTop={onTableScrollTop} />
		<Modal
			open={visible}
			width={834}
			onCancel={() => setVisible(false)}
			footer={null}
		>
			<p>玩家 <span
				className="font-bold text-pink-500 text-xl">{curWhatCanIGetInfo.user.personaname}</span> 将可以游玩以下Ta未拥有的游戏
			</p>
			{
				curWhatCanIGetInfo.familySharingStocks.length > 0 && <>
					<p className="font-bold text-lg my-2">加入这个家庭您将能玩到的新游戏</p>
					<Flex wrap>
						{
							curWhatCanIGetInfo.familySharingStocks.map((game) => {
								return <Tag onClick={() => onGameNameClick(game.steam_appid)} key={game.steam_appid} bordered={false} color="cyan" className="cursor-pointer m-1">{game.name}</Tag>;
							})
						}
					</Flex>
				</>
			}
			{
				curWhatCanIGetInfo.notFamilySharingStocks.length > 0 && <>
					<p className="font-bold text-lg my-2">很抱歉，虽然您家庭的其他成员拥有以下游戏，但是它们还暂不支持家庭共享，敬请期待它们以后加入家庭共享</p>
					<Flex wrap>
						{
							curWhatCanIGetInfo.notFamilySharingStocks.map((game) => {
								return <Tag onClick={() => onGameNameClick(game.steam_appid)} key={game.steam_appid} bordered={false} color="volcano" className="cursor-pointer m-1">{game.name}</Tag>;
							})
						}
					</Flex>
				</>
			}
		</Modal>
		<Modal
			open={gameInfoVisible}
			width={834}
			onCancel={() => setGameInfoVisible(false)}
			footer={null}>
			<p className="text-2xl font-bold mb-2">{curGameInfo.name}</p>
			<span className="text-2xl font-bold my-2 text-[#1677ff] cursor-pointer hover:text-[#69b1ff] mr-4" onClick={() => onGameNameClick(curGameInfo.steam_appid)}>Steam 详情页面</span>
			{
				curGameInfo.website &&
				<a className="text-2xl font-bold my-2" href={curGameInfo.website} target="_blank" rel="noreferrer">游戏官网</a>
			}
			<p className="text-2xl font-bold my-2">类别</p>
			{
				curGameInfo.categories?.map((c) => <Tag key={c.id} color={getRandomColor()} className="m-1">{c.description}</Tag>)
			}
			<p dangerouslySetInnerHTML={{__html: curGameInfo.detailed_description}}></p>
		</Modal>
	</Card>;
};

export default GameTable;

export const SearchFilteror = () => {
	return <div>
		<Button type="text" icon={<SearchOutlined/>}/>
		<Button type="text" icon={<FilterOutlined/>} className="ml-2"/>
	</div>;
};

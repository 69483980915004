import { message, Tooltip } from "antd";

/**
 * 复制文本到剪贴板
 * @param {string} text 要复制的文本
 */
export const copyToClipboard = async (text) => {
	try {
		await navigator.clipboard.writeText(text);
		message.success('复制成功');
	} catch (err) {
		console.log('Failed to copy: ', err);
		message.error(`复制失败，请手动复制 \n ${text}`, 10);
	}
};
/**
 * 复制 url 并带上查询参数
 * @param {Object} options
 * @param {URL} options.url 要复制的 url
 * @param {Object} options.params 要带上的查询参数
 */
export const  coypUrlWithParams = (options) => {
	const { url = new URL(window.location.href), params = {} } = options;
	// 判断 url 是否是 URL 类型
	if (!(url instanceof URL)) {
		message.error('url 参数必须是 URL 类型');
		return;
	}
	if (!params) return copyToClipboard(url.href);
	Object.keys(params).forEach(key => {
		url.searchParams.set(key, params[key]);
	});
	copyToClipboard(url.href);
};

// gamers 类型 { user: object, stock: object   } []
/**
 * 格式化库存数据
 * @param {Array} gamers 玩家库存数据
 * @returns {Array} 格式化后的库存数据
 */
export const formatStock = (gamers) => {
	const result = [];
	gamers.forEach((gamer) => {
		const { user, stock } = gamer;
		stock.forEach((game) => {
			if (!game) {
				return;
			}
			result.push({
				...game,
				subordinate: user.steamid,
			});
		});
	});
	// 合并 result 中相同 game 的数据，但是subordinate不同
	return result.reduce((acc, cur) => {
		if (acc.find((item) => item?.steam_appid === cur?.steam_appid)) {
			const index = acc.findIndex((item) => item?.steam_appid === cur?.steam_appid);
			acc[index] = {
				...acc[index],
				subordinate: `${acc[index].subordinate},${cur.subordinate}`.split(','),
			};
		} else {
			acc.push(cur);
		}
		return acc;
	}, []).sort((a, b) => a.name.localeCompare(b.name));
};

/**
 * 点击游戏名称跳转到 steam 页面
 * @param {string} appId 游戏 id
 * @param {Object} options 选项
 * @param {boolean} options.newWindow 是否在新窗口打开，默认 true
 */
export const onGameNameClick = (appId, options) => {
	const { newWindow = true } = options || {};
	if (!newWindow) {
		window.location.href = `https://store.steampowered.com/app/${appId}`;
		return;
	}
	window.open(`https://store.steampowered.com/app/${appId}`, '_blank');
};

/**
 * 处理 Tooltip 文本
 * @param {string} text 要显示的文本
 * @param {Object} options 选项
 * @param {number} options.rows 显示的行数，默认 1
 * @param {number} options.maxWidth 最大宽度，默认 500
 * */
export const dealToolTipText = (text, options) => {
	const { rows = 1, maxWidth = 500 } = options ?? {};
	const item = <div style={{
		maxWidth: '100%',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-inline-box',
		WebkitLineClamp: rows,
		WebkitBoxOrient: 'vertical',
	}}>{text}</div>;
	return (
		<Tooltip title={text} overlayStyle={{ maxWidth }} >
			{item}
		</Tooltip>
	);
};

/**
 * 获取 Storage
 * @param {string} key 键
 * @param {Object} options 选项
 * @param {string} options.type 存储类型，默认 localStorage
 * @returns {any} 获取到的值
 * */
export const getStorage = (key, options) => {
	const { type = 'localStorage' } = options ?? {};
	const storage = window[type];
	if (!storage) {
		console.error('storage is not exist');
		return null;
	}
	const value = storage.getItem(key);
	if (!value) return null;
	try {
		return JSON.parse(value);
	} catch (error) {
		return value;
	}
};

/**
 * 设置 Storage
 * @param {string} key 键
 * @param {any} value 值
 * @param {Object} options 选项
 * @param {string} options.type 存储类型，默认 localStorage
 * */
export const setStorage = (key, value, options) => {
	const { type = 'localStorage' } = options ?? {};
	const storage = window[type];
	if (!storage) {
		console.error('storage is not exist');
		return;
	}
	if (typeof value === 'object') {
		storage.setItem(key, JSON.stringify(value));
		return;
	}
	storage.setItem(key, value);
};

const COLORS = ['magenta', 'red', 'volcano', 'orange', 'gold', 'lime', 'green', 'cyan', 'blue', 'geekblue', 'purple'];
/**
 * 获取随机颜色
 * @returns {string} 颜色
 * */
export const getRandomColor = () => {
	return COLORS[Math.floor(Math.random() * COLORS.length)];
};

import { useState } from "react";
import { Modal, FloatButton, Tooltip, Collapse } from 'antd';
import { QuestionCircleOutlined, ShareAltOutlined } from '@ant-design/icons';
import getSteamKey from '@/assets/images/get-steam-key.png';
import getSelfExample from '@/assets/images/get-self-steam-id-example.png';
import getOthersExample from '@/assets/images/get-others-steam-id-example.png';
import phoneVerify from '@/assets/images/phone-verify.png';
import { clickEvent } from "../../utils/tracking";

const items = [
	{
		key: '1',
		label: '如何使用？',
		children: <p>在首页输入框中输入多个玩家的 steam id，每个 steam id 一行，点击提交按钮，等待加载完成后即可查看共享库存信息。</p>,
	},
	{
		key: '2',
		label: '无法使用？',
		children: <p>正常情况下会自动从数据库中获取可用的 Steam key 用于查询，如果数据库中无可用的 Steam key，您可以在右上角添加自己的 Steam key。</p>,
	},
	{
		key: '3',
		label: '如何获取 Steam Key？',
		children: <div>
			<p>打开链接 <a href=" https://steamcommunity.com/dev" target="_blank">https://steamcommunity.com/dev</a> ,登录 Steam 账号点击申请即可,申请时需要验证手机令牌，如图二</p>
			<img className="mt-2" src={getSteamKey} alt="" />
			<img className="mt-2" src={phoneVerify} alt="" />
		</div>
	},
	{
		key: '4',
		label: '如何获取自己的 steam id？',
		children: <div>
			<p>打开 Steam 客户端，点击自己的头像，选择“个人资料”，在地址栏中即可看到自己的 steam id。</p>
			<img className="mt-2" src={getSelfExample} alt="" />
		</div>
	},
	{
		key: '5',
		label: '如何获取好友的 steam id？',
		children: <div>
			<p>打开 Steam 客户端，点击好友列表，选择好友，点击“个人资料”，在地址栏中即可看到好友的 steam id。</p>
			<img className="mt-2" src={getOthersExample} alt="" />
		</div>
	},
	]
;

const FloatButtonGroup = (props) => {
	const [visible, setVisible] = useState(false);
	const onClickGuide = () => {
		clickEvent('Guide');
		setVisible(true);
	};
	const onShared = () => {
		props.onShared();
	};
	const onTableScrollTop = () => {
		clickEvent('TableScrollTop');
		props.onTableScrollTop();
	};
	return <div>
		<FloatButton.Group shape="circle" style={{ right: 24 }}>
			<FloatButton onClick={onClickGuide} icon={<QuestionCircleOutlined />} />
			<Tooltip placement="left" title="点击分享按钮，将当前页面的数据生成一个链接，并复制到剪切板，直接将链接分享给好友即可" >
				<FloatButton onClick={onShared} icon={<ShareAltOutlined />} />
			</Tooltip>
			<FloatButton.BackTop onClick={onTableScrollTop} visibilityHeight={0} />
		</FloatButton.Group>
		<Modal
			open={visible}
			width={834}
			onCancel={() => setVisible(false)}
			footer={null}
		>
			<p className="text-xl font-bold px-[16px] mb-2">指南</p>
			<Collapse defaultActiveKey={['1']} ghost items={items} />
		</Modal>
	</div>;
};

export default FloatButtonGroup;

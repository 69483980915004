import { useEffect, useRef, useState } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { setStorage } from "../../utils";
import { clickEvent } from "../../utils/tracking";
import './index.css';

const adTitle = '追剧刷视频流量不够用？试试这个神器，秒变流量土豪！';

const Marquee = ({ text, speed = 50 }) => {
	const marqueeRef = useRef(null);
	const textRef = useRef(null);
	const [isOverflowing, setIsOverflowing] = useState(false);

	useEffect(() => {
		const marqueeWidth = marqueeRef.current.offsetWidth;
		const textWidth = textRef.current.scrollWidth;
		if (textWidth > marqueeWidth) {
			setIsOverflowing(true);
		}
	}, [text]);

	return (
		<div
			className="w-full overflow-hidden whitespace-nowrap"
			ref={marqueeRef}
		>
			<div
				className="inline-block"
				ref={textRef}
				style={{
					animation: isOverflowing
						? `marquee ${text.length / speed}s linear infinite`
						: 'none',
					whiteSpace: 'nowrap'
				}}
			>
				{text}
			</div>
		</div>
	);
};

const AD = (props) => {
	const onCloseAd = () => {
		setStorage('isShowAd', false, { type: 'sessionStorage' });
		props.onClose();
	};
	const onAdClick = () => {
		clickEvent('AD');
		window.open('https://haokawx.lot-ml.com/Product/Index/615968', '_blank');
	};
	// return <Marquee text={`${adTitle} 点击了解详情`} speed={10} />;
	return <div className="h-[40px] flex justify-center items-center px-8 py-2 bg-gradient-to-r from-violet-500 to-fuchsia-500 text-white hover:text-gray-50">
		<div onClick={onAdClick} className="flex justify-center max-lg:w-full">
			<Marquee text={`${adTitle} 点击了解详情`} speed={2} />
		</div>
		<CloseCircleOutlined onClick={onCloseAd} className="ml-2" />
	</div>;
};

export default AD;

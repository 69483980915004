import {useEffect, useState} from 'react';
import {Input, Checkbox, Button, Modal, Tooltip, message} from 'antd';
import { CheckCircleTwoTone, StopTwoTone } from '@ant-design/icons';
import Icon from '@/components/Icon';
import { clickEvent } from "../../utils/tracking";
import { getStorage, setStorage } from "../../utils";

export default function Header(props) {
  const { keyStatus, onChangeKeyStatus } = props;
  const [visible, setVisible] = useState(false);
  const onGithubClick = () => {
    clickEvent('Github');
    window.open('https://github.com/DevilC0822', '_blank');
  };
  const onClickAbout = () => {
    clickEvent('About');
    setVisible(true);
  };
  const [addVisible, setAddVisible] = useState(false);
  const [inputKey, setInputKey] = useState('');
  const [isAddPublic, setIsAddPublic] = useState(false);
  const onAddKey = () => {
    setAddVisible(true);
  };
  const onInputChange = (e) => {
    setInputKey(e.target.value);
  };
  const onCheckboxChange = (e) => {
    setIsAddPublic(e.target.checked);
  };
  const verifyKey = (key) => {
    return fetch(`${process.env.REACT_APP_BASE_API}/key/verify?key=${key}`).then(res => res.json()).then(res => {
      return res.success;
    });
  };
  const onSubmitKey = async () => {
    if (!inputKey) {
      message.error('请输入 key');
      return;
    }
    const isAlive = await verifyKey(inputKey);
    if (!isAlive) {
      message.error('key 无效');
      return;
    }
    let successMsg = '添加 key 成功';
    if (isAddPublic) {
      await fetch(`${process.env.REACT_APP_BASE_API}/key/add`, {
        method: 'post',
        body: JSON.stringify({ key: inputKey}),
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      }).then(res => res.json()).then(() => {
        successMsg += '，非常感谢你贡献了一份 Steam Key，它可以帮助更多无法获取 Key 的用户使用此项目';
      });
    }
    setStorage('steam_key', inputKey);
    onChangeKeyStatus(true);
    setAddVisible(false);
    message.success(successMsg);
  };
  const onDonation = () => {
    setIsAddPublic(true);
    setAddVisible(true);
  };
  const getPublicKeyStatus = async () => {
    if (getStorage('steam_key')) {
      const isAlive = await verifyKey(getStorage('steam_key'));
      if (isAlive) {
        onChangeKeyStatus(true);
        return;
      }
      setStorage('steam_key', '');
    }
    fetch(`${process.env.REACT_APP_BASE_API}/key/status`).then(res => res.json()).then(res => {
      if (!res.success) {
        message.error('目前无公共 key，请点击右上方红色按钮手动添加 key');
        return;
      }
      onChangeKeyStatus(true);
    });
  };
  useEffect( () => {
    getPublicKeyStatus();
  }, []);
  return (
    <header className="sticky top-0 z-20 border-transparent backdrop-blur-md shadow">
      <div className="flex items-center justify-between px-4 md:px-8 py-2">
        <div className="flex gap-2 items-center h-12">
          <h1 className="text-xl font-bold">Steam 家庭共享库预览</h1>
        </div>
        <div className="flex items-center justify-end gap-1 md:gap-2">
          {
            keyStatus && <Tooltip title={<>将使用服务器公共 Steam key，如果你想帮助更多人可以 <span onClick={onDonation} className="font-bold cursor-pointer text-blue-400">捐献 Steam key</span></>}>
              <CheckCircleTwoTone twoToneColor="#52c41a" />
            </Tooltip>
          }
          {
            !keyStatus && <Tooltip title="目前无公共 key，请手动添加 key">
              <StopTwoTone onClick={onAddKey} className="cursor-pointer" twoToneColor="#eb2f96" />
            </Tooltip>
          }
          <Button type="text" onClick={onGithubClick} icon={<Icon type="icon-github-fill" size={20} />}/>
          <Button type="primary" onClick={onClickAbout}>关于</Button>
        </div>
      </div>
      <Modal
        open={addVisible}
        width={400}
        onCancel={() => setAddVisible(false)}
        footer={null}
      >
        <div className="p-4">
          <p className="text-xl font-bold mb-2">添加 Steam key</p>
          <p>请在下方输入框中输入您的 Steam key，点击提交按钮即可完成设置。</p>
          <Input value={inputKey} onChange={onInputChange} className="mt-2" />
          <div className="flex justify-between items-center">
            <Checkbox checked={isAddPublic} onChange={onCheckboxChange}>将此 Key 添加入公共库中</Checkbox>
            <Button onClick={onSubmitKey} type="primary" className="mt-2">提交</Button>
          </div>
        </div>
      </Modal>
      <Modal
        open={visible}
        width={834}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <div>
          <p className="text-xl font-bold mb-2">这是什么？</p>
          <p>一个基于 Steam Web API 开发的网站，通过填入多个玩家的 steam id，形成一个共享库存的信息列表，方便玩家在组建家庭共享之前预览，使玩家组建家庭的利益最大化。</p>
          <p className="text-xl font-bold my-2">为什么要做这个项目？</p>
          <p>
            最近 Steam 推出了一种新的<a href="https://store.steampowered.com/news/app/593110/view/4149575031735702628" target="_blank" rel="noreferrer">游戏库家庭共享方案</a>，简单来说支持六个用户组建成新的家庭，而这五个人可以游玩所有成员的游戏（即并集）。同时相比过去老的家庭共享功能，新的Steam家庭中玩家可以在其他玩家在线的同时游玩家庭库列表中的共享游戏，也支持离线访问，但是需要注意的是同一款游戏不能共同访问，除非这款游戏在家庭库中有多个副本。这一方案刚推出引起了很多玩家的热烈讨论，并有很多玩家在社区内寻找优质成员组建成新的家庭。
          </p>
          <p>
            由于<span className="font-bold">加入到家庭中再退出存在一年的冷却期（离开后席位也有一年的冷却期）</span>，所以为了避免玩家在加入家庭后失望不满，所以我想着利用 Steam Web API 开发出一个 Web 应用，方便各位玩家在加入前知晓自己加入后可以获得哪些新游戏的游玩权限而开发。
          </p>
          <p className="text-xl font-bold my-2">如何使用？</p>
          <p>在首页输入框中输入多个玩家的 steam id，每个 steam id 一行，点击提交按钮，等待加载完成后即可查看共享库存信息。</p>
          <p className="text-xl font-bold my-2">技术栈</p>
          <p>前端：React + TailwindCSS + Ant Design</p>
          <p>后端：Node.js + Express + MongoDB</p>
          <p className="text-xl font-bold my-2">问题反馈</p>
          <p>如果您在使用过程中遇到问题，或者有更好的建议，欢迎发送邮件至 <a href="mailto:devilc0822@gmail.com">devilc0822@gmail.com</a>。</p>
          <p className="text-xl font-bold my-2">项目地址</p>
          <a href="https://www.constfuns.xyz/constfuns/Steam-96b1cbb576a945de9451a5f242599da7" target="_blank" rel="noreferrer">Steam 家庭共享预览</a>
          <p className="text-red-500 text-center mt-4">免责声明：本项目仅供学习交流使用，不得用于商业用途，如有侵权请联系作者删除。</p>
        </div>
      </Modal>
    </header>
  );
}
